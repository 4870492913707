import { block } from "bem-cn";

import { text404 } from "assets/constants";

import Layout from "layouts/";
import Heading from "components/Heading";
import Container from "components/Container";
import Paragraph from "components/Paragraph";
import Link from "components/Link";

import "./404.scss";

const cn = block("page-404");

const seo = {
  title: "Page not found | GIAnalyzer",
  description: "",
  link: "/404/",
  noForRobots: true,
};

const Page404 = () => (
  <Layout seo={seo}>
    <Container className={cn("wrap")}>
      <span className={cn("404")}>{text404["404"]}</span>
      <div className={cn("content")}>
        <Heading color="general" weight="bold" className={cn("title")}>
          {text404.pageNotFoundText}
        </Heading>
        <Paragraph>{text404.notExistPageText}</Paragraph>
        <Paragraph>{text404.checkUrlText}</Paragraph>
        <Paragraph>
          Or you can go to the{" "}
          <Link href="/" color="primary">
            Home page
          </Link>
          , or browse{" "}
          <Link href="/docs/" color="primary">
            Knowledge base
          </Link>
          , or navigate the menu to find what you are looking for.
        </Paragraph>
      </div>
    </Container>
  </Layout>
);

export default Page404;
